.dndflow {
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}
.ant-tabs-tabpane {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.dndnode {
  padding: 4px 8px;
  background-color: white;
  margin: 5px;
  border-radius: 4px;
  color: black;
  border: 1px solid #e0e0e0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: center;

  & svg {
    height: 20px;
    margin-right: 4px;
  }
  & path {
    fill: black;
  }
}
.dndflow aside > * {
  margin-bottom: 10px;
  cursor: grab;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 180px;
  }
}

.react-flow__handle {
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

.timeline {
  border-radius: 10px;
  min-height: 100%;
}
.flag {
  background: #db554d !important;
}
.warning {
  background: #ff9028 !important;
  /* color: white !important; */
}

.timeline-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.timeline-card > div > .timeline-img {
  margin-top: 17px;
}

.warning-flag > div > p {
  color: white !important;
}

.warning-flag > div > .title {
  font-weight: bold;
  margin: 5px 0px;
}

.warning-flag > .content {
  max-width: 60%;
}

.warning-flag > div > .title-2 {
  margin-bottom: 3px;
}

.warning-flag > div {
  height: 100%;
  min-width: 12%;
}

.warning-flag > div > img {
  height: 60%;
  margin: 0 auto;
}

.horizontal-tabs > .tab {
  display: inline-block;
  width: 50%;
  text-align: center;
  margin-top: 20px;
  padding-bottom: 10px;
}

.horizontal-tabs {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background: #fafafa;
}

.selected {
  border-bottom: 2px solid #78b8ff;
}

.status-indicator > * {
  display: inline-block;
}

.hidden {
  display: hidden !important;
}

.ant-form {
  box-sizing: border-box;
  font-size: 14px;
}

.ant-input-affix-wrapper {
  box-sizing: border-box;
  font-size: 14px;
  position: relative;
  display: inline-block;
  width: 100%;
}

.ant-input-affix-wrapper .ant-input-prefix {
  position: absolute;
  top: 16px;
  margin-left: 0px;
  z-index: 1;
}

.ant-form-item {
  margin-bottom: 8px;
}

.ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  content: "*";
}
.ant-input {
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  padding: 4px 30px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.ant-btn-primary {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 32px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.ant-form-explain {
  color: #f5222d;
  font-size: 12px;
  margin-left: 4px;
}

.knowledge-icon {
  height: 70px;
  width: 70px;
}

.timeline-item-container {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.conversational-timeline {
  overflow-y: scroll;
  background: #fafafa;
  box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 20px 10px;
  max-height: 800px;
}

.item-icon {
  height: 30px;
  width: 30px;
}

.feedback {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.feedback-buttons {
  width: 60px;
}
@import "~antd/dist/antd.css";
@import "./css/variables.scss";

body {
  margin: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /*
  & > * {
    scrollbar-color: #999999 #eeeeee;
    scrollbar-width: thin;
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #eeeeee;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;    
      background-color: transparent;
    }
    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: #666666;
      }
    }
  }    
  */ 
}

body .ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item.select-all {
  & .anticon-check {
    display: none;
  }
}

body .css-rubric-builder-question-options {
  width: 150px !important;
}

body .ant-time-picker-panel-input {
  pointer-events: none;
}

body .ant-calendar-footer {
  text-transform: capitalize;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
